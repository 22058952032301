* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  color: white
}

body {
  background: var(--background-color);
}

a {
  text-decoration: none;
  color: unset;
}

.section__margin {
  margin: 4rem 6rem;
}

.section__padding {
  padding: 4rem 6rem;
}

.scale-up-center {
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade__background {
  background:radial-gradient(circle at 0% 0%, #041729 0%, #030811 30%);
  background:-moz-radial-gradient(circle at 0% 0%, #041729 0%, #030811 30%);
  background:-webkit-radial-gradient(circle at 0% 0%, #041729 0%, #030811 30%);
  background:-ms-radial-gradient(circle at 0% 0%, #041729 0%, #030811 30%);
  background:-o-radial-gradient(circle at 0% 0%, #041729 0%, #030811 30%);
}

.gradient__text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: var(--grad-text);
  background-clip: text;
}

@media screen and (max-width: 600px) {
  .section__padding {
    padding: 5rem 2rem;
  }

  .section__margin {
    margin: 5rem 2rem;
  }  
}

@media screen and (max-width: 600px) {
  .section__padding {
    padding: 5rem;
  }

  .section__margin {
    margin: 5rem;
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}