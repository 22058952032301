@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;
  --background-color: #030811;
  --footer-color : #081624;
  --subtext-color: #f3846b;
  --grad-text: linear-gradient(85deg, #ffa760 2%, #fa4b2c 105%);
  --grad-bar: linear-gradient(105deg, #ee742d -20%, #ee742d00 100%);
}
