@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  color: white
}

body {
  background: var(--background-color);
}

a {
  text-decoration: none;
  color: unset;
}

.section__margin {
  margin: 4rem 6rem;
}

.section__padding {
  padding: 4rem 6rem;
}

.scale-up-center {
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade__background {
  background:radial-gradient(circle at 0% 0%, #041729 0%, #030811 30%);
  background:-o-radial-gradient(circle at 0% 0%, #041729 0%, #030811 30%);
}

.gradient__text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: var(--grad-text);
  background-clip: text;
}

@media screen and (max-width: 600px) {
  .section__padding {
    padding: 5rem 2rem;
  }

  .section__margin {
    margin: 5rem 2rem;
  }  
}

@media screen and (max-width: 600px) {
  .section__padding {
    padding: 5rem;
  }

  .section__margin {
    margin: 5rem;
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.det__Description {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--footer-color);
    background:radial-gradient(circle at 10% -100%, #06111b 25%, rgb(3, 22, 41) 85%, rgb(1, 23, 44) 100%);

    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.det__Description-detail {
    display: flex;
}

/* Customizing detail component as per needs */
.det__Description-detail .det__details-cont_detail {
    margin: 0;
}

/* Customizing detail component as per needs */
.det__Description-detail .det__details-cont_detail-text {
    max-width: 700px;
}

.det__Description-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.det__Description-heading h1 {
    font-size: 35px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 520px;
}

.det__Description-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 17px;
    line-height: 32px;
    color: var(--subtext-color);
    cursor: pointer;
}

.det__Description-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2.25rem;
}

/* Customizing detail component as per needs */
.det__Description-container .det__details-cont_detail {
    flex: 1 1;
    margin: 1rem;
    min-width: 220px;
    display: unset;
    flex-direction: column;
}

/* Customizing detail component as per needs */
.det__Description-container .det__details-cont_detail-text {
    margin-top: 0.6rem;
}

@media screen and (max-width: 875px) {
    .det__Description-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .det__Description-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 675px) {
    /* Customizing detail component as per needs */
    .det__Description-detail .det__details-cont_detail {
        flex-direction: column;
    }

    /* Customizing detail component as per needs */
    .det__Description-detail .det__details-cont_detail-text {
        margin-top: 0.6rem;
    }

    .det__Description-heading h1 {
        font-size: 29px;
        line-height: 42px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing detail component as per needs */
    .det__Description-container .det__details-cont_detail {
        margin: 1rem 0;
        min-width: 100%;
    }
}
.det__details-cont_detail-title {
    flex: 1 1;
    max-width: 185px;
    margin-right: 2.5rem;
}

.det__details-cont_detail-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 19px;
    line-height: 25px;
    letter-spacing: -0.05em;
    color: #fff;
}

.det__details-cont_detail {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1.5rem;
}

.det__details-cont_detail-title div {
    width: 40px;
    height: 3.5px;
    background: var(--grad-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 0.25rem;
}

.det__details-cont_detail-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: var(--color-text);
}

.det__details-cont_detail-text {
    flex: 2 1;
    max-width: 400px;
    display: flex;
}

@media screen and (max-width: 550px) {
    .det__details-cont_detail-title h1 {
        font-size: 15px;
        line-height: 25px;
    }

    .det__details-cont_detail-text p {
        font-size: 13px;
        line-height: 24px;
    }

    .det__details-cont_detail {
        margin: 1rem 0;
    }
}
.det__details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.det__details-heading {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.det__details-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.det__details-heading p {
    color: var(--subtext-color);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);

    margin-top: 2rem;
}

.det__details-container {
    flex: 1.75 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 1000px) {
    .det__details {
        flex-direction: column;
    }

    .det__details-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 600px) {
    .det__details-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}
.det__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--footer-color);
}

.det__footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 4.5rem;
}

.det__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 820;
    font-size: 65px;
    line-height: 80px;
}

.det__footer-links {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    text-align: left;
}

.det__footer-links div {
    width: 250px;
    margin: 1rem;
}

.det__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.det__footer-links_logo img {
    width: 80px;
    height: 30px;
    margin-bottom: 1rem;
}

.det__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 13px;
    line-height: 16px;
    color: #fff;
}

.det__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.det__footer-links_div h4 {
    font-size: 15px;
    line-height: 18px;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 0.95rem;
}

.det__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.6rem 0;
    cursor: pointer;
}

.det__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.det__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 900px) {
    .det__footer-heading h1 {
        font-size: 45px;
        line-height: 50px;
    }
}

@media screen and (max-width: 575px) {
    .det__footer-heading h1 {
        font-size: 35px;
        line-height: 40px;
    }

    .det__footer-links div {
        margin: 1rem 0;
    }
}

@media screen and (max-width: 400px) {
    .det__footer-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

.det__header {
    display: flex;
    flex-direction: row;
}

.det__header-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.det__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
    letter-spacing: -0.05em;
}

.det__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 1.75rem;
}

.det__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-top: 2rem;
}

.det__header-content__people img {
    width: 180px;
    height: 38px;
}

.det__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.det__header-image {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.det__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1000px) {
    .det__header {
        flex-direction: column;
    }

    .det__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 700px) {
    .det__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .det__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .det__header-content__people {
        flex-direction: column;
    }

    .det__header-content__people p {
        margin: 0;
    }
}

@media screen and (max-width: 500px) {
    .det__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .det__header-content p {
        font-size: 15px;
        line-height: 24px;
    }
}
.det__navbar-links_container p,
.det__navbar-sign p,
.det__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1.5rem;
    cursor: pointer;
}

.det__navbar-sign button,
.det__navbar-menu_container button {
    padding: 1rem 1.5rem;
    color: #fff;
    background: #fd451b;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5.5px;
}

.det__navbar-menu svg {
    cursor: pointer;
}

.det__navbar-links {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.det__navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 6rem;
}

.det__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.det__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.det__navbar-links_logo {
    margin-right: 3rem;
}

.det__navbar-links_logo img {
    width: 50px;
    height: 20px;
}

.det__navbar-menu {
    margin-left: 1.5rem;

    display: none;
    position: relative;
}

.det__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--footer-color);
    padding: 2.5rem;
    position: absolute;
    right: 0;
    top: 42px;
    margin-top: 1rem;
    min-width: 220px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.det__navbar-menu_container-links-sign {
    display: none;
}

.det__navbar-menu_container p {
    margin: 1rem 0;
}

@media screen and (max-width: 1100px) {
    .det__navbar-links_container {
        display: none;
    }

    .det__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .det__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .det__navbar {
        padding: 2rem;
    }

    .det__navbar-sign {
        display: none;
    }

    .det__navbar-menu_container {
        top: 20px;
    }

    .det__navbar-menu_container-links-sign {
        display: block;
    }
}

:root {
  --font-family: 'Manrope', sans-serif;
  --background-color: #030811;
  --footer-color : #081624;
  --subtext-color: #f3846b;
  --grad-text: linear-gradient(85deg, #ffa760 2%, #fa4b2c 105%);
  --grad-bar: linear-gradient(105deg, #ee742d -20%, #ee742d00 100%);
}

