.det__details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.det__details-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.det__details-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.det__details-heading p {
    color: var(--subtext-color);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);

    margin-top: 2rem;
}

.det__details-container {
    flex: 1.75;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 1000px) {
    .det__details {
        flex-direction: column;
    }

    .det__details-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 600px) {
    .det__details-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}