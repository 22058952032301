.det__details-cont_detail-title {
    flex: 1;
    max-width: 185px;
    margin-right: 2.5rem;
}

.det__details-cont_detail-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 19px;
    line-height: 25px;
    letter-spacing: -0.05em;
    color: #fff;
}

.det__details-cont_detail {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1.5rem;
}

.det__details-cont_detail-title div {
    width: 40px;
    height: 3.5px;
    background: var(--grad-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 0.25rem;
}

.det__details-cont_detail-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: var(--color-text);
}

.det__details-cont_detail-text {
    flex: 2;
    max-width: 400px;
    display: flex;
}

@media screen and (max-width: 550px) {
    .det__details-cont_detail-title h1 {
        font-size: 15px;
        line-height: 25px;
    }

    .det__details-cont_detail-text p {
        font-size: 13px;
        line-height: 24px;
    }

    .det__details-cont_detail {
        margin: 1rem 0;
    }
}