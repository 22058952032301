.det__Description {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--footer-color);

    background:-moz-radial-gradient(circle at 10% -100%, #06111b 25%, rgb(3, 22, 41) 85%, rgb(1, 23, 44) 100%);
    background:-webkit-radial-gradient(circle at 10% -100%, #06111b 25%, rgb(3, 22, 41) 85%, rgb(1, 23, 44) 100%);
    background:-o-radial-gradient(circle at 10% -100%, #06111b 25%, rgb(3, 22, 41) 85%, rgb(1, 23, 44) 100%);
    background:-ms-radial-gradient(circle at 10% -100%, #06111b 25%, rgb(3, 22, 41) 85%, rgb(1, 23, 44) 100%);
    background:radial-gradient(circle at 10% -100%, #06111b 25%, rgb(3, 22, 41) 85%, rgb(1, 23, 44) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.det__Description-detail {
    display: flex;
}

/* Customizing detail component as per needs */
.det__Description-detail .det__details-cont_detail {
    margin: 0;
}

/* Customizing detail component as per needs */
.det__Description-detail .det__details-cont_detail-text {
    max-width: 700px;
}

.det__Description-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.det__Description-heading h1 {
    font-size: 35px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 520px;
}

.det__Description-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 17px;
    line-height: 32px;
    color: var(--subtext-color);
    cursor: pointer;
}

.det__Description-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2.25rem;
}

/* Customizing detail component as per needs */
.det__Description-container .det__details-cont_detail {
    flex: 1;
    margin: 1rem;
    min-width: 220px;
    display: unset;
    flex-direction: column;
}

/* Customizing detail component as per needs */
.det__Description-container .det__details-cont_detail-text {
    margin-top: 0.6rem;
}

@media screen and (max-width: 875px) {
    .det__Description-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .det__Description-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 675px) {
    /* Customizing detail component as per needs */
    .det__Description-detail .det__details-cont_detail {
        flex-direction: column;
    }

    /* Customizing detail component as per needs */
    .det__Description-detail .det__details-cont_detail-text {
        margin-top: 0.6rem;
    }

    .det__Description-heading h1 {
        font-size: 29px;
        line-height: 42px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing detail component as per needs */
    .det__Description-container .det__details-cont_detail {
        margin: 1rem 0;
        min-width: 100%;
    }
}